<style lang="less" scoped>
.table_updateuser tr {
  height: 60px !important;
  line-height: 60px;
  > td:nth-child(1) {
    width: 80px;
    color: #000;
    font-size: 16px;
  }
  /deep/ .ant-input {
    width: 100%;
    height: 32px;
    line-height: 32px;
  }
}
/deep/ .ant-input-affix-wrapper {
  line-height: 32px;
}
.userinfo {
  span {
    display: inline-block;
    width: 80px;
    text-align: left;
    color: #000;
    font-size: 16px;
  }
}
</style>
<template>
  <a-card :bordered="false">
    <div class="page-header-index-wide page-header-wrapper-grid-content-main" style="text-align: center">
      <div class="userinfo">
        <table style="width: 100%">
          <tr>
            <td colspan="1" rowspan="3" style="width: 200px; height: 200px">
              <img :src="userInfo.avatarUrl" width="180px" height="180px" />
            </td>
            <td style="text-align: left; width: 200px" colspan="2"><span>姓名：</span>{{ userInfo.name }}</td>
          </tr>
          <tr>
            <td style="text-align: left" colspan="2"><span>ID：</span>{{ userInfo.id }}</td>
          </tr>
          <tr>
            <td style="text-align: left" colspan="2"><span>手机号：</span>{{ userInfo.mobile }}</td>
          </tr>
          <tr>
            <td style="height: 68px" colspan="1">&nbsp;</td>
            <td style="text-align: left"><span>岗位：</span>{{ userInfo.roleNames }}</td>
            <td colspan="1" rowspan="3" style="width: 200px; height: 200px">
              <div
                id="qrcode"
                ref="qrcode"
                style="vertical-align: middle; text-align: center; width: 180px; height: 180px; padding-top: 45px"
              ></div>
            </td>
          </tr>
          <tr>
            <td style="height: 68px" colspan="1">&nbsp;</td>
            <td style="text-align: left"><span>邮箱：</span>{{ userInfo.email }}</td>
          </tr>
          <tr>
            <td style="height: 68px" colspan="1">&nbsp;</td>
            <td style="text-align: left">
              <span> 绑定微信：</span> <a-tag :color="userInfo.statusColor"> {{ userInfo.wxBingdStatus }}</a-tag
              >，请“用微信扫码”,绑定成功后
              <a-button style="padding: 0 !important" type="link" @click="initUserInfo">点击此处刷新</a-button>
            </td>
          </tr>
        </table>
        <a-button type="primary" style="margin-left: -75px; margin-right: 8px" @click="updateUserInfo"
          >更改信息</a-button
        >
        <a-button type="primary" style="margin-left: 8px; margin-right: 8px" @click="updateUserMobile"
          >变更手机号</a-button
        >
        <a-button type="primary" style="margin-left: 8px; margin-right: 8px" @click="updateUserWx" v-show="userInfo.wxBingdStatus=='已绑定'"
        >变更微信</a-button
        >
        <a-button type="primary" style="margin-left: 8px; margin-right: 8px" @click="updateUserPasswd"
        >修改登录密码</a-button
        >
      </div>
    </div>
    <a-modal
      key="updateinfo"
      title="更新信息"
      :width="500"
      :visible="visible_updateinfo"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :closable="true"
      @cancel="updateinfoCancel"
      :centered="true"
      :mask="true"
      :footer="null"
      :maskClosable="false"
      :body-style="{ height: 'auto' }"
    >
      <div style="height: 440px; overflow-y: auto">
        <table style="width: 100%" class="table_updateuser">
          <tr>
            <td align="left">头像：</td>
            <td>
              <a-upload
                v-model:file="updateUserInfoVo.avatarUrl"
                name="file"
                list-type="picture-card"
                class="avatar-uploader"
                :show-upload-list="false"
                :headers="headers"
                :beforeUpload="beforeUpload"
                action="/service_provider/user/uploadUserAvatar"
                @change="uploadChange"
              >
                <img v-if="imageUrl" :src="imageUrl" width="100px" height="100px" alt="avatar" />
                <div v-else>
                  <!--                              <loading-outlined v-if="loading"></loading-outlined>-->
                  <!--                              <plus-outlined v-else></plus-outlined>-->
                  <div class="ant-upload-text">Upload</div>
                </div>
              </a-upload>
            </td>
          </tr>
          <tr>
            <td align="left">手机号：</td>
            <td align="left">{{ updateUserInfoVo.mobile }}</td>
          </tr>
          <tr>
            <td align="left">岗位：</td>
            <td align="left">{{ updateUserInfoVo.roleNames }}</td>
          </tr>
          <tr>
            <td align="left">ID：</td>
            <td align="left">{{ updateUserInfoVo.id }}</td>
          </tr>
          <tr>
            <td align="left">姓名：</td>
            <td align="left">
              <a-input v-model="updateUserInfoVo.name" name="name" />
            </td>
          </tr>
          <tr>
            <td align="left">邮箱：</td>
            <td align="left">
              <a-input v-model="updateUserInfoVo.email" name="name"
              />
            </td>
          </tr>
        </table>
      </div>
      <div style="text-align: right;">
        <a-button style="margin-left: 255px; margin-right: 18px" @click="updateinfoCancel">取消</a-button>
        <a-button @click="submitupdateinfo" type="primary">提交</a-button>
      </div>
    </a-modal>
    <a-modal
      key="updatemobile"
      title="更新手机号"
      :width="500"
      :visible="visible_updatemobile"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :closable="true"
      @cancel="updatemobileCancel"
      :centered="true"
      :mask="true"
      :footer="null"
      :maskClosable="false"
      :body-style="{ height: 'auto' }"
    >
      <div style="height: 150px; overflow-y: auto">
        <table style="width: 100%" class="table_updateuser">
          <tr>
            <td align="left" style="color:blue">手机号：</td>
            <td align="left" colspan="2"><a-input v-model="updateUserInfoVo.mobile" name="name" /></td>
          </tr>
          <tr>
            <td align="left" style="color:blue">验证码：</td>
            <td align="left" style="width: 280px">
              <a-input v-model="updateUserInfoVo.verificationCode" name="name" style="width: 100%" />
            </td>
            <td>
              <a-button
                class="getCaptcha"
                tabindex="-1"
                :disabled="state.smsSendBtn"
                @click.stop.prevent="getCaptcha"
                v-text="(!state.smsSendBtn && '获取验证码') || state.time + ' s'"
              ></a-button>
            </td>
          </tr>
        </table>
      </div>
      <div style="text-align: right;">
        <a-button style="margin-left: 252px; margin-right: 18px" @click="updatemobileCancel">取消</a-button>
        <a-button @click="submitupdatemobile" type="primary">提交</a-button>
      </div>
    </a-modal>

    <a-modal
      key="updatewx"
      title="更新微信"
      :width="400"
      :visible="visible_updatewx"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :closable="true"
      @cancel="updatewxCancel"
      :centered="true"
      :mask="true"
      :footer="null"
      :maskClosable="false"
      :body-style="{ height: '320px' }"
    >
      <div style="height: 240px;">
       <div style="padding-left: 40px">请微信扫描二维码，成功后点击关闭按钮</div>
        <div
          id="qrcode2"
          ref="qrcode2"
          style="vertical-align: middle; text-align: center; width: 180px; height: 180px; padding-top: 15px;padding-left: 80px"
        ></div>
      </div>
      <div>
        <a-button style="margin-left: 152px; margin-right: 18px" @click="updatewxCancel">关闭</a-button>
      </div>
    </a-modal>


    <a-modal
      key="updatepasswd"
      title="更新密码"
      :width="500"
      :visible="visible_updatePasswd"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :closable="true"
      @cancel="updatePasswdCancel"
      :centered="true"
      :mask="true"
      :footer="null"
      :maskClosable="false"
      :body-style="{ height: 'auto' }"
    >
      <div style="height: 260px; ">
        <table style="width: 100%" class="table_updateuser">
          <tr>
            <td align="left" style="width: 30%">手机号：</td>
            <td align="left" colspan="2" ><a-input v-model="updateUserInfoVo.mobile" readonly="true" name="mobile" /></td>
          </tr>
          <tr>
            <td align="left" >验证码：</td>
            <td align="left" style="width: 280px">
              <a-input v-model="updateUserInfoVo.verificationCode" name="name" style="width: 100%" />
            </td>
            <td>
              <a-button
                class="getCaptcha"
                tabindex="-1"
                :disabled="state.smsSendBtn"
                @click.stop.prevent="getCaptcha(4)"
                v-text="(!state.smsSendBtn && '获取验证码') || state.time + ' s'"
              ></a-button>
            </td>
          </tr>
          <tr>
            <td align="left" >密码：</td>
            <td align="left" colspan="2"><a-input v-model="updateUserInfoVo.password" name="name"    oninput = "value=value.replace(/[\u4E00-\u9FA5]/g,'')"/></td>
          </tr>
          <tr>
            <td align="left" >确认密码：</td>
            <td align="left" colspan="2"><a-input v-model="updateUserInfoVo.password1" name="name"   oninput = "value=value.replace(/[\u4E00-\u9FA5]/g,'')" /></td>
          </tr>

        </table>
      </div>
      <div style="text-align: right;">
        <a-button style="margin-left: 252px; margin-right: 18px" @click="updatePasswdCancel">取消</a-button>
        <a-button @click="submitupdatePasswd" type="primary">提交</a-button>
      </div>
    </a-modal>

  </a-card>
</template>

<script>
import { message } from 'ant-design-vue'
import { UploadChangeParam, UploadProps } from 'ant-design-vue'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue'
import { defineComponent, ref } from 'vue'
import QRCode from 'qrcodejs2'
import {getUserInfo, saveRole, updatePasswd, updateUser, updateUserMobile} from '@/api/manage'
import Vue from 'vue'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { getSmsCaptcha } from '@/api/login'

export default {
  name: 'UserMenu',
  components: {
    LoadingOutlined,
    PlusOutlined,
  },
  data() {
    return {
      userInfo: {},
      updateUserInfoVo: {},
      visible_updateinfo: false,
      visible_updatemobile: false,
      visible_updatewx:false,
      visible_uploadimg: false,
      visible_updatePasswd: false,
      loading: false,
      confirmLoading: false,
      imageUrl: '',
      interval:null,//短信发送定时器
      state: {
        time: 60,
        loginBtn: false,
        // login type: 0 email, 1 username, 2 telephone
        loginType: 0,
        smsSendBtn: false,//默认不隐藏
      },
    }
  },
  computed: {
    headers() {
      let token = Vue.ls.get(ACCESS_TOKEN)
      return {
        token: token,
      }
    },
  },
  methods: {
    handleLogout() {},
    updateUserInfo() {
      this.visible_updateinfo = true
      this.updateUserInfoVo = JSON.parse(JSON.stringify(this.userInfo))
    },
    updateUserMobile() {
      this.visible_updatemobile = true
      this.updateUserInfoVo = JSON.parse(JSON.stringify(this.userInfo))
    },
    updateinfoCancel() {
      this.visible_updateinfo = false
    },
    updatemobileCancel() {
      this.visible_updatemobile = false
    },
    submitupdateinfo() {
      let that = this
      let values = {}
      values.id = this.updateUserInfoVo.id
      values.email = this.updateUserInfoVo.email
      values.name = this.updateUserInfoVo.name
      const emailTest = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
      if(!emailTest.test(values.email)){
        that.$message.error("邮箱错误，请核对")
        return false
      }
      if(this.updateUserInfoVo.avatarUrl!=null){
        if (this.updateUserInfoVo.avatarUrl.indexOf(this.ossbasepath) == 0) {
          values.avatarUrl = this.updateUserInfoVo.avatarUrl
        } else {
          values.avatarUrl = this.updateUserInfoVo.avatarUrl.substr(
            this.updateUserInfoVo.avatarUrl.indexOf(this.ossbasepath)
          )
        }
      }


      updateUser(values)
        .then((res) => {
            if(res.code==0) {
              //提交成功
              this.$message.success('保存成功')
              this.visible_updateinfo = false
              this.initUserInfo()
              this.updateUserInfoVo = {}
            }else{
              this.$message.error(res.message)
            }
        })
        .catch((e) => {
          this.$message.error('保存失败')
          this.visible_updateinfo = false
          this.updateUserInfoVo = {}
        })
    },
    submitupdatemobile() {
      let that = this
      let values = {}
      values.id = this.updateUserInfoVo.id
      values.mobile = this.updateUserInfoVo.mobile
      values.verificationCode = this.updateUserInfoVo.verificationCode
      values.smsType=3
      updateUserMobile(values)
        .then((res) => {
          //提交成功
          if (res.code == 0) {
            this.$message.success('更新成功')
            this.visible_updatemobile = false
            this.initUserInfo()
            this.updateUserInfoVo = {}
          } else {
            this.$message.error(res.message)
          }
        })
        .catch((e) => {
          this.$message.error('更新失败')
          this.visible_updatemobile = false
          this.updateUserInfoVo = {}
        })
    },
    beforeUpload(file) {
      let isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'|| file.type === 'image/jpg';
      if (!isJpgOrPng) {
        this.$message.error('格式错误，只能上传jpg、jpeg、png');
        return reject(false);
      }
      return isJpgOrPng;
    },
    uploadChange(info) {
      if (info.file.status === 'uploading') {
        this.loading = true
        return
      }
      if (info.file.status === 'done') {
        let fileName = info.file.response.result
        let url = '/ly_user_center/sso/getUserAvatar?fileName=' + fileName
        //this.userInfo.avatarUrl = url
        this.imageUrl = url
        this.updateUserInfoVo.avatarUrl = fileName
      }
    },
    initUserInfo() {
      getUserInfo({})
        .then((res) => {
          if (res.result != null) {
            this.userInfo = {}
            this.userInfo.name = res.result.name
            this.userInfo.id = res.result.id
            this.userInfo.mobile = res.result.mobile
            this.userInfo.email = res.result.email
            if (res.result.openId == null || res.result.openId == '') {
              this.userInfo.wxBingdStatus = '未绑定'
              this.userInfo.statusColor = 'red'
            } else {
              this.userInfo.wxBingdStatus = '已绑定'
              this.userInfo.statusColor = 'green'
            }
            let roleNames = ''
            if (res.result.roleDtoList != null) {
              for (let i in res.result.roleDtoList) {
                roleNames += res.result.roleDtoList[i].name
              }
            }
            this.userInfo.roleNames = roleNames
            if (res.result.avatarUrl != null && res.result.avatarUrl != "") {
              let url = '/ly_user_center/sso/getUserAvatar?fileName=' + res.result.avatarUrl
              this.userInfo.avatarUrl = url
            }else {
              let url = 'https://yihlengyun-public.oss-cn-beijing.aliyuncs.com/icon/defaultuser.png'
              this.userInfo.avatarUrl = url
            }
          }

          this.$nextTick(function () {
            if(this.userInfo.wxBingdStatus!="已绑定"){
              document.getElementById('qrcode').innerHTML = ''
              let token = Vue.ls.get(ACCESS_TOKEN)
              let url =
                'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx6689a961ce9c4843&' +
                'redirect_uri=' +
                this.domain +
                '%2Fly_user_center%2Fsso%2FupdateOpenId%3Fyih_token%3D' +
                token +
                '&response_type=code&scope=snsapi_base&state=123#wechat_redirect'
              this.qrcode = new QRCode('qrcode', {
                width: 180,
                height: 180,
                colorDark: '#000',
                colorLight: '#fff',
                correctLevel: QRCode.CorrectLevel.L, // 容错率，L/M/H
                text: url,
              })
            }
          })
        })
        .catch((e) => {
          this.$message.error('查询当前登录信息失败')
        })
    },
    getCaptcha(e) {
      let that = this
      if (this.updateUserInfoVo.mobile == null || this.updateUserInfoVo.mobile == '') {
        this.$message.error('手机号不能为空')
        return false
      }
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if ( !reg.test(this.updateUserInfoVo.mobile )) {
        this.$message.error('手机号不正确')
        return false
      }
      clearInterval(this.interval)
      this.state.smsSendBtn = true
      this.interval = window.setInterval(() => {
        if (this.state.time-- <= 0) {
          this.state.time = 60
          this.state.smsSendBtn = false
          window.clearInterval(this.interval)
        }
      }, 1000)
      let smsType = 3;
      if(4==e){
        smsType=4
      }
      const hide = this.$message.loading('验证码发送中..', 0)
      getSmsCaptcha({ mobile: this.updateUserInfoVo.mobile, smsType: smsType })
        .then((res) => {
          if(res.code!=0){
            this.$message.error(res.message);
            setTimeout(hide, 1)
            clearInterval(this.interval)
            this.state.time = 60
            this.state.smsSendBtn = false
          }else{
            setTimeout(hide, 2500)
            this.$notification['success']({
              message: '提示',
              description: '验证码获取成功',
              duration: 8,
            })
          }

        })
        .catch((err) => {
          setTimeout(hide, 1)
          clearInterval(this.interval)
          this.state.time = 60
          this.state.smsSendBtn = false
          this.requestFailed(err)
        })
    },
    updateUserWx(){
      this.visible_updatewx = true
      this.$nextTick(function () {
          document.getElementById('qrcode2').innerHTML = ''
          let token = Vue.ls.get(ACCESS_TOKEN)
          let url =
            'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx6689a961ce9c4843&' +
            'redirect_uri=' +
            this.domain +
            '%2Fly_user_center%2Fsso%2FupdateOpenId%3Fyih_token%3D' +
            token +
            '&response_type=code&scope=snsapi_base&state=123#wechat_redirect'
          new QRCode('qrcode2', {
            width: 180,
            height: 180,
            colorDark: '#000',
            colorLight: '#fff',
            correctLevel: QRCode.CorrectLevel.L, // 容错率，L/M/H
            text: url,
          })
      })
    },
    updateUserPasswd(){
      this.visible_updatePasswd = true
      this.updateUserInfoVo = JSON.parse(JSON.stringify(this.userInfo))
      clearInterval(this.interval)
      this.state.time = 0
      this.state.smsSendBtn = false
    },
    updatePasswdCancel(){
      this.visible_updatePasswd = false
    },
    updatewxCancel(){
      this.visible_updatewx = false
      this.initUserInfo()
    },
    submitupdatePasswd(){
      let that = this
      let values = {}
      values.id = this.updateUserInfoVo.id
      values.password = this.updateUserInfoVo.password
      values.password1 = this.updateUserInfoVo.password1
      values.verificationCode = this.updateUserInfoVo.verificationCode
      values.mobile=this.updateUserInfoVo.mobile
      values.smsType = 4
      if(values.password==null||values.password1==null||values.password!=values.password1){
        this.$message.error("两次输入密码不一致，请核对")
        return false
      }
      updatePasswd(values)
        .then((res) => {
          //提交成功
          if (res.code == 0) {
            this.$message.success('更新成功')
            this.visible_updatePasswd = false
            this.initUserInfo()
            this.updateUserInfoVo = {}
          } else {
            this.$message.error(res.message)
          }
        })
        .catch((e) => {
          this.$message.error('更新失败')
          this.visible_updatePasswd = false
          this.updateUserInfoVo = {}
        })
    }
  },
  mounted: function () {
    let that = this
    this.initUserInfo()
  },
}
</script>
<style lang="less" scoped>
.userinfo {
  text-align: center;
  width: 800px;
  height: 500px;
  border: 1px solid white;
  background: white;
}
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.table_updateuser td {
  height: 40px;
}
</style>
